import { library, dom } from "@fortawesome/fontawesome-svg-core";

// You have to import individually because our bundler isn't very smart...
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faSquareXTwitter } from "@fortawesome/free-brands-svg-icons/faSquareXTwitter";
import { faVimeoSquare } from "@fortawesome/free-brands-svg-icons/faVimeoSquare";
import { faYoutubeSquare } from "@fortawesome/free-brands-svg-icons/faYoutubeSquare";

library.add([faInstagram, faLinkedin, faFacebookSquare, faSquareXTwitter, faVimeoSquare, faYoutubeSquare]);

dom.watch();