import React from "react";
import PropTypes from "prop-types";
import InlineSearch from "../InlineSearch/InlineSearch";
import $ from "jquery";

function stopBodyScrolling(bool) {
  if (bool === true) {
    document.body.addEventListener("touchmove", freezeVp, false);
  } else {
    document.body.removeEventListener("touchmove", freezeVp, false);
  }
}

const freezeVp = function (e) {
  e.preventDefault();
};

class HamburgerMenu extends React.Component {
  static propTypes = {
    menus: PropTypes.array,
    isSignInOn: PropTypes.bool,
    isSearchOn: PropTypes.bool,
    searchInNav: PropTypes.bool,
    showSubscribeBtn: PropTypes.bool,
    includeSubscribe: PropTypes.bool
  };

  static defaultProps = {
    menus: [],
    isSignInOn: false,
    isSearchOn: false,
    searchInNav: false,
    showSubscribeBtn: true,
    includeSubscribe: false
  };

  state = {
    open: false,
    menu: {}
  };
  createMarkup = (html) => {
    return {
      __html: html
    };
  };
  _toggleMenu = () => {
    if (!this.state.open) {
      $("html").addClass("fixed");
      $("#TopAd").addClass("closed");
      $("#MainHeader").addClass("mobile-open");
      stopBodyScrolling(true);
    } else {
      $("html").removeClass("fixed");
      $("#TopAd").removeClass("closed");
      $("#MainHeader").removeClass("mobile-open");
      stopBodyScrolling(false);
    }
    this.setState({ open: !this.state.open });
  };
  _toggleMenuItem = (idx) => {
    let menu = Object.assign({}, this.state.menu);

    menu.children[idx].open = !menu.children[idx].open;

    this.setState({ menu });
  };
  _getMenuItemContent = (child) => {
    return <a href={child.url} dangerouslySetInnerHTML={this.createMarkup(child.label)} onClick={this._toggleMenu} />;
  };
  test = (e) => {
    e.preventDefault();
  };
  _displayMenuItems = (child, idx) => {
    let hasChildren = typeof child.children !== "undefined" && child.children.length > 0;

    return (
      <div key={idx} className={child.open ? "open" : "closed"}>
        <li className="menuItem">{this._getMenuItemContent(child, idx, hasChildren)}</li>
        <ul className="subMenuItems">
          {hasChildren &&
            child.children.map((childOfChild, index) => {
              return (
                <li className="subMenuItem" key={index}>
                  <a href={childOfChild.url} dangerouslySetInnerHTML={this.createMarkup(childOfChild.label)} />
                </li>
              );
            })}
        </ul>
      </div>
    );
  };
  _displayMenu = () => {
    let hasChildren = typeof this.state.menu.children !== "undefined" && this.state.menu.children.length > 0;

    return (
      <div className="menu-list-wrapper">
        <ul className="menuItems">
          {this.props.searchInNav && <InlineSearch classes="menu-search" isSearchOn={true} open={true} static={true} />}
          {this.props.isSignInOn && (
            <li className="font-secondary text-primary subMenuItem">
              <a href="/sign-in">Sign In</a>
            </li>
          )}
          {this.props.isSignInOn && (
            <li className="font-secondary text-primary subMenuItem">
              <a href="/register">Register</a>
            </li>
          )}
          {this.props.showSubscribeBtn && !this.props.searchInNav && (
            <li className="font-secondary text-primary subMenuItem mobile-subscribe">
              <a href="/subscribe">Subscribe</a>
            </li>
          )}
          <li className="menuItem">
            <a
              href="/category-group/clinical-focus"
              dangerouslySetInnerHTML={this.createMarkup("Applications")}
              onClick={this._toggleMenu}
            />
          </li>
          <li className="menuItem">
            <a
              href="/category-group/management-careers"
              dangerouslySetInnerHTML={this.createMarkup("Management & Careers")}
              onClick={this._toggleMenu}
            />
          </li>
          <li className="menuItem">
            <a
              href="/category-group/tools-techniques"
              dangerouslySetInnerHTML={this.createMarkup("Technology")}
              onClick={this._toggleMenu}
            />
          </li>
          <li className="menuItem">
            <a href="/type/news" dangerouslySetInnerHTML={this.createMarkup("News")} onClick={this._toggleMenu} />
          </li>
          <li className="menuItem">
            <a
              href="/type/thought-leadership"
              dangerouslySetInnerHTML={this.createMarkup("Thought Leadership")}
              onClick={this._toggleMenu}
            />
          </li>
          <li className="menuItem">
            <a href="/type/webinars" dangerouslySetInnerHTML={this.createMarkup("Events")} onClick={this._toggleMenu} />
          </li>
          <li className="menuItem">
            <a href="/series/trends" dangerouslySetInnerHTML={this.createMarkup("Trends")} onClick={this._toggleMenu} />
          </li>
          <li className="menuItem">
            <a href="/" dangerouslySetInnerHTML={this.createMarkup("Resources")} onClick={this._toggleMenu} />
          </li>
          {this.props.showSubscribeBtn && this.props.searchInNav && (
            <li className="font-secondary text-primary subMenuItem mobile-subscribe">
              <a href="/subscribe">Subscribe Today!</a>
            </li>
          )}
        </ul>
      </div>
    );
  };

  componentDidMount() {
    let menus = [...this.props.menus];

    let menu = {};

    for (let i = 0; i < menus.length; i++) {
      if (menus[i].label === "Main Navigation") {
        menu = menus[i];
      }
    }
    if (menu.children) {
      for (let i = 0; i < menu.children.length; i++) {
        menu.children[i].open = false;
      }
    }
    this.setState({ menu });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.includeSubscribe && (
          <a className="no-hover-underline" href="/subscribe">
            <button className="action-btn subscribe-btn">Subscribe</button>
          </a>
        )}
        <div className={this.state.open ? "menu open" : "menu"}>
          <button className="btn menu-button open-btn" title="Open Menu" onClick={this._toggleMenu}>
            <i className="far fa-bars" aria-hidden="true" />
          </button>
          <div className="menu-list">
            <a id="menu-brand" href="/"></a>
            <button className="menu-button close-btn" title="Close Menu" onClick={this._toggleMenu}>
              <i className="far fa-times" aria-hidden="true" />
            </button>
            {this._displayMenu()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HamburgerMenu;
