import React from "react";
import PropTypes from "prop-types";

class InlineSearch extends React.Component {
    static propTypes = {
        isSearchOn: PropTypes.bool,
        static: PropTypes.bool,
        open: PropTypes.bool,
        classes: PropTypes.string
    };

    static defaultProps = {
        isSearchOn: true,
        static: false,
        open: false,
        classes: ""
    };

    state = {
        searchTerm: "",
        open: false
    };

    _openSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({open: true}, ()=>{
            var that = this;

            setTimeout(function(){
                that.refs.searchInput.focus();
            }, 500);
        });
    }

    _search = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if(this.state.searchTerm.trim()) {
            // Do search
            window.location = "/search?searchTerm=" + encodeURIComponent(this.state.searchTerm);
        }
    }
    _closeSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({open: false});
    }
    _handleChange = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const updates = Object.assign({}, this.state);

        updates[event.target.name] = event.target.value;

        this.setState(updates);
    } // close _handleChange

    componentDidMount() {
        this.setState({
            open: this.props.open ? this.props.open : false,
            static: this.props.static ? this.props.static : false
        });
    }

    render() {
        let classes = this.props.classes ? `InlineSearchContainer ${this.props.classes} ` : "InlineSearchContainer ";
        if(this.props.isSearchOn) {
            return (
                <div className={classes + (this.state.open ? "open" : "closed") + (this.state.static ? " static" : " dynamic")}>
                    {!this.state.static && <button className="icon-btn open-btn" title="Open Search" onClick={ this._openSearch }>
                        <i className="far fa-search" aria-hidden="true"/>
                    </button>}
                    <div className="InlineSearchForm">
                        <form onSubmit={this._search }>
                            <input ref="searchInput" type="text" className="search-input" name="searchTerm" value={this.state.searchTerm } onChange={ this._handleChange } placeholder="Search..." disabled={(this.state.open ? "" : true)} />
                            {this.state.static &&  <button type="submit" className="icon-btn submit-btn" title="Search">
                                <i className="far fa-search" aria-hidden="true"/>
                            </button>}
                        </form>
                        {!this.state.static && <button className="icon-btn close-btn" title="Close Search" onClick={ this._closeSearch }>
                            <i className="far fa-times" aria-hidden="true"/>
                        </button>}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    } // close render
} // close InlineSearch

export default InlineSearch;
