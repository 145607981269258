/*
 * Comments about React components code split:
 *
 * This file contains 5 components that are used on most pages of LM/CLM/DDN. It is bundled by base:js job in assets.config.js
 * Other components are only used on a few pages or not shared by 3 sites. They have their own Index.jsx file and bundle. Check the components array in assets.config.js for more information
 *
 * If you are creating a new component that is shared on all/most pages for LM/CLM/DDN, please include it here
 * If you are creating a new component that is used on only a few pages, please create a new Index.jsx file in its folder and update the components array in assets.config.js
 *
 * You may want to create a new job for a specific page if that page has many extra components that aren't the below 5. Refer to articleViewPage for an example
 **
 * This wiki page would also be helpful if you want to get a list of all routes/views of LM/CLM/DDN
 * https://gitlab.labx.com/docs/frontend/-/wikis/LM-CLM-DDN-Web-Pages
 */

import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
const { register } = require("@lpm/pug-components/dist/register");

// SSR components
import CookiePolicy from "./CookiePolicy/CookiePolicy";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import InlineSearch from "./InlineSearch/InlineSearch";
import Pagination from "./Pagination/Pagination";

// CSR components
import Newsletter from "./Newsletter/Newsletter";

// React SSR
register("CookiePolicy/CookiePolicy", CookiePolicy);
register("HamburgerMenu/HamburgerMenu", HamburgerMenu);
register("InlineSearch/InlineSearch", InlineSearch);
register("Pagination/Pagination", Pagination);

// React CSR
const newsletter = document.getElementById("Newsletter");

// Not react
const currentIssues = document.getElementsByClassName("CurrentIssue");
const articleGroups = document.getElementsByClassName("ArticleGroup");
const articleTabLists = document.getElementsByClassName("ArticleTabList");

if (newsletter) {
  ReactDOM.render(<Newsletter config={JSON.parse(newsletter.dataset.config)} />, newsletter);
}

// Not react
if (currentIssues) {
  for (let i = 0; i < currentIssues.length; i++) {
    let params = {
      issidebar: currentIssues[i].dataset.issidebar ? "true" : ""
    };
    axios
      .get("/api/v2/current-issue", {
        params
      })
      .then((response) => {
        currentIssues[i].innerHTML = response.data;
      });
  }
}

if (articleGroups) {
  for (let i = 0; i < articleGroups.length; i++) {
    let ad = articleGroups[i].dataset.ad ? articleGroups[i].dataset.ad : "";

    let params = {
      title: articleGroups[i].dataset.title ? articleGroups[i].dataset.title : "",
      slugs: articleGroups[i].dataset.slugs ? articleGroups[i].dataset.slugs : "",
      exclude: articleGroups[i].dataset.exclude ? articleGroups[i].dataset.exclude : "",
      ad
    };

    axios
      .get("/api/v2/article-group", {
        params
      })
      .then((response) => {
        articleGroups[i].innerHTML = response.data;
        if (ad) {
          //init ad if one is being loaded
          let adSlots = articleGroups[i].querySelectorAll("[data-zone]");
          let loadNewAdsEvent = new CustomEvent("loadNewAds", { detail: adSlots });

          window.dispatchEvent(loadNewAdsEvent);
        }
      });
  }
}

if (articleTabLists) {
  for (let i = 0; i < articleTabLists.length; i++) {
    let params = {};
    if (articleTabLists[i].dataset.config) {
      params = JSON.parse(articleTabLists[i].dataset.config);

      const { id = "", ad = "", category = "", tag = "", imageloading = "auto" } = articleTabLists[i].dataset;

      params.id = id;
      params.ad = ad;
      params.category = category;
      params.tag = tag;
      params.imageloading = imageloading;

      if (params.slugs) {
        params.slugs = params.slugs.join(",");
      }
      if (params.magazineSections) {
        params.magazineSections = params.magazineSections.join(",");
      }
    }

    if (params.id == "Sponsored") {
      axios
        .get("/api/v2/article-tab-list/sponsored-pre-footer", {
          params
        })
        .then((response) => {
          articleTabLists[i].innerHTML = response.data;
          if (params.ad) {
            let adSlots = articleTabLists[i].querySelectorAll("[data-zone]");

            adSlots.forEach(function (slot) {
              if (params.adTargetId && params.adTargetName) {
                slot.classList.remove("unamedAd");
                slot.classList.add(`${params.adTargetName}-ad`);
                slot.setAttribute("data-adtargetvalue", +params.adTargetId);
              }
            });
            let loadNewAdsEvent = new CustomEvent("loadNewAds", { detail: adSlots });

            window.dispatchEvent(loadNewAdsEvent);
          }
        });
    } else {
      axios
        .get("/api/v2/article-tab-list", {
          params
        })
        .then((response) => {
          articleTabLists[i].innerHTML = response.data;
          if (params.ad) {
            let adSlots = articleTabLists[i].querySelectorAll("[data-zone]");

            adSlots.forEach(function (slot) {
              if (params.adTargetId && params.adTargetName) {
                slot.classList.remove("unamedAd");
                slot.classList.add(`${params.adTargetName}-ad`);
                slot.setAttribute("data-adtargetvalue", +params.adTargetId);
              }
            });
            let loadNewAdsEvent = new CustomEvent("loadNewAds", { detail: adSlots });

            window.dispatchEvent(loadNewAdsEvent);
          }
        });
    }
  }
}
