import { library, dom } from "@fortawesome/fontawesome-svg-core";

// You have to import individually because our bundler isn't very smart...
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight";

library.add([
    faChevronLeft,
    faChevronRight
]);

dom.watch();