import React from "react";

const Newsletter = ({config}) => {
    return (
        <div id="NewsletterSignUp" className="background-secondary lazy-background">
            <div className="cover-img">
                <img src={`/assets/${config.image.src}`} alt={config.image.alt} title={config.image.title} style={{width: "358px", height: "300px"}}/>
            </div>
            <div className="cover-text">
                <div className="h1 text-white">{config.title}</div>
                <p className="text-white">{config.summary}</p>
                <button className="action-btn inverted" onClick={() => {
                    window.location = config.button.url ? config.button.url : "/";
                }}>{config.button.text}</button>
            </div>
        </div>
    );
};

export default Newsletter;
