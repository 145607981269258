import "babel-polyfill";
import $ from "jquery";
import AOS from "aos";
import shortid from "shortid";
import "./fontawesome/fontawesome";

const { initialize } = require("@lpm/pug-components/dist/initialize");

initialize();

var isMobileView = null;
var isMobileViewThreshold = 1023; //$bp-sm-max
var gptAdSlots = [];

var isChromium = window.chrome;
var winNav = window.navigator;
var vendorName = winNav.vendor;
var isOpera = typeof window.opr !== "undefined";
var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
var isIOSChrome = winNav.userAgent.match("CriOS");
var isIE = winNav.userAgent.indexOf("MSIE ") !== -1 || winNav.appVersion.indexOf("Trident/") > 0;

var isChrome = false;

if (isIOSChrome) {
  isChrome = true;
} else if (
  isChromium !== null &&
  typeof isChromium !== "undefined" &&
  vendorName === "Google Inc." &&
  isOpera === false &&
  isIEedge === false
) {
  isChrome = true;
}

// AOS for infographics
window.AOS = AOS;

var topLeaderboardSlot = null;
var topLeaderboardRefreshCount = 0;

$(document).ready(function () {
  $(this).scrollTop(0);
  $("body").removeClass("preload");
  $("body").addClass("hasJS");
  if (isChrome) {
    $("body").addClass("isChrome");
  } else {
    $("body").addClass("notChrome");
  }
  if (isIE) {
    $("body").addClass("isIE");
  } else {
    $("body").addClass("notIE");
  }

  let isInfographic = $("body").hasClass("infographic-layout");

  if (!isInfographic) {
    AOS.init({
      offset: 50,
      disable: function () {
        return window.innerWidth < isMobileViewThreshold;
      }
    });
  }
  $(document).scroll(adjustHeader);

  var articleView = $("#ArticleView");

  articleInit(articleView);

  //Fix for Admin Team entering article header images into the body of the article
  if ($("main").hasClass("lm-default") || $("main").hasClass("product")) {
    // Used CSS to better target "fake hero" image and remove padding
    $("p").addClass("default-body");
    $("p").has("span").has("img").addClass("article-image-wrapper");
  }

  //If IntersectionObersver API is not supported by the browser
  if (!("IntersectionObserver" in window)) {
    var script = document.createElement("script");

    script.src = "https://raw.githubusercontent.com/w3c/IntersectionObserver/master/polyfill/intersection-observer.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  $(document).on("click", "a[href^='#']", function (e) {
    e.preventDefault();
    var targetID = e.currentTarget.hash.replace("#", "");
    var element = document.getElementById(targetID);

    window.location.hash = "#" + targetID;

    var offset = $(element).offset();
    var scrollto = offset.top - 60;
    $("html, body").animate({ scrollTop: scrollto }, 0);
  });

  $(window).resize(() => {
    _handleResize();
  });
});

$(document).on("DOMContentLoaded", function () {
  whenGoogleTagManagerIsReady().then(setupGoogleTagManager);

  // trigger loading of new ads from lazy loaded content
  window.addEventListener("loadNewAds", function (data) {
    var newAdSlots = data.detail;

    whenGoogleTagManagerIsReady().then(() => {
      setupGoogleTagManager(newAdSlots);
    });
  });

  startSetupOfArticleObserver();
  setupLazyBackgroundObserver(document);
});

const articleInit = (articleView) => {
  if (articleView) {
    //collapse Multimedia for Article Pages with really small articles
    if (articleView.height() < 500) {
      var media = $("#Media");

      if (media.length == 1) {
        media.collapse();
      }
    }

    var articleBody = articleView.find("article");

    if (articleBody) {
      articleBody.find("a").each((index, link) => {
        var linkElem = $(link);

        var GTMData = {
          pageType: "Article",
          clickURL: linkElem.attr("href"),
          clickType: "Article Body",
          articleNo: articleBody.attr("data-articleno"),
          authorNo: articleBody.attr("data-authorno"),
          ip: clientIP
        };

        // only add default GTMDate if it's not been set
        if (!linkElem.attr("data-gtm-data")) {
          linkElem.addClass("gtm-click").attr("data-gtm-data", JSON.stringify(GTMData));
        }
      });

      articleBody.find("table").wrap("<div class='table-wrapper'></div>");
    }
  }
};

const whenGoogleTagManagerIsReady = () => {
  return new Promise((resolve) => {
    if (window.googletag) {
      resolve();
    } else {
      setTimeout(() => {
        whenGoogleTagManagerIsReady().then(resolve);
      }, 250);
    }
  });
}; // close whenGoogleTagManagerIsReady

const _triggerAdRefresh = function (refreshSlots) {
  googletag.cmd.push(() => {
    setTimeout(() => {
      if (refreshSlots == undefined || !refreshSlots.length) {
        googletag.pubads().refresh();
      } else {
        googletag.pubads().refresh(refreshSlots);
      }
    }, 100);
  });
};

const _refreshTopLeaderboard = function () {
  googletag.pubads().refresh([topLeaderboardSlot]);
  topLeaderboardRefreshCount += 1;
  if (topLeaderboardRefreshCount < 3) {
    setTimeout(_refreshTopLeaderboard, 20000);
  }
};

const _handleResize = function () {
  var currentMobileValue = JSON.parse(JSON.stringify(isMobileView));

  adjustHeader();

  if ($(window).width() <= isMobileViewThreshold) {
    isMobileView = true;
    $("body").addClass("isMobileView");
  } else {
    isMobileView = false;
    $("body").removeClass("isMobileView");
  }
  if (currentMobileValue != isMobileView) {
    // if coming is mobile view
    if (isMobileView) {
      // Relocate Ads
      const adMoveTo = document.querySelectorAll(`.relocate-mobile-ad`);
      const adMoveFrom = document.querySelectorAll(`.relocate-desktop-ad + .advertisement`);
      adMoveFrom.forEach((node, index) => {
        $(adMoveTo[index]).after($(node));
      });

      // Relocate Asset Info (article view header/banner)
      const firstParagraph =
        document.querySelector("#ArticleBody .event p") || document.querySelector("#ArticleBody>p");
      const assetInfo = document.querySelectorAll(".asset-info-hero");
      assetInfo.forEach((node) => {
        const assetInfoWrapper = $("<div class='asset-info-wrapper'></div>").append($(node));
        $(firstParagraph).after($(assetInfoWrapper));
      });
    } else {
      // Relocate Ads
      const adMoveFrom = document.querySelectorAll(`.relocate-mobile-ad + .advertisement`);
      const adMoveTo = document.querySelectorAll(`.relocate-desktop-ad`);
      adMoveFrom.forEach((node, index) => {
        $(adMoveTo[index]).after($(node));
      });

      // Relocate Asset Info (article view header/banner)
      const assetInfoMoveFrom = document.querySelectorAll("#ArticleBody .asset-info-hero");
      const assetInfoMoveTo = document.querySelectorAll(".relocate-desktop-asset-info");
      const assetInfoWrapper = document.querySelectorAll(".asset-info-wrapper");
      assetInfoMoveFrom.forEach((node, index) => {
        $(assetInfoMoveTo[index]).after($(node));
      });
      $(assetInfoWrapper).remove();
    }
    if (window.googletag && currentMobileValue != null) {
      _triggerAdRefresh();
    }
  }
};

const setupGoogleTagManager = (adSlots) => {
  var advertisements = adSlots;

  // when lazy loading article ads trigered on mobile view, remove ads to mobile ads position
  if (adSlots && isMobileView && $(".ArticleSection.isLoaded").length) {
    const adMoveTo = document.querySelectorAll(`.ArticleSection.isLoaded .relocate-mobile-ad`);
    const adMoveFrom = document.querySelectorAll(`.ArticleSection.isLoaded .relocate-desktop-ad + .advertisement`);

    adMoveFrom.forEach((node, index) => {
      $(adMoveTo[index]).after($(node));
    });
  }

  // if no adSlots, load what is on page
  if (!adSlots) {
    advertisements = document.querySelectorAll("[data-zone]");
  }

  gptAdSlots = [];
  googletag.cmd.push(() => {
    var topLeaderboardMapping = googletag
      .sizeMapping()
      .addSize(
        [980, 0],
        [
          [970, 90],
          [728, 90]
        ]
      )
      .addSize([800, 0], [[728, 90]])
      .addSize(
        [0, 0],
        [
          [320, 50],
          [300, 50]
        ]
      )
      .build();
    var bottomLeaderboardMapping = googletag
      .sizeMapping()
      .addSize([768, 0], [[728, 90]])
      .addSize(
        [0, 0],
        [
          [320, 50],
          [300, 50]
        ]
      )
      .build();
    var smallLeaderboardMapping = googletag
      .sizeMapping()
      .addSize([500, 0], [468, 60])
      .addSize(
        [0, 0],
        [
          [468, 60],
          [300, 50]
        ]
      )
      .build();
    var boomboxMapping = googletag
      .sizeMapping()
      .addSize([0, 0], [[300, 250]])
      .build();
    var torpedoMapping = googletag
      .sizeMapping()
      .addSize(
        [1000, 0],
        [
          [1000, 60],
          [960, 45]
        ]
      )
      .addSize(
        [0, 0],
        [
          [468, 60],
          [320, 50],
          [300, 50]
        ]
      )
      .build();
    var halfPageMapping = googletag
      .sizeMapping()
      .addSize(
        [800, 600],
        [
          [300, 600],
          [300, 250]
        ]
      )
      .addSize([0, 0], [300, 250])
      .build();
    var billBoardMapping = googletag
      .sizeMapping()
      .addSize([1024, 450], [[970, 250]])
      .addSize(
        [0, 0],
        [
          [320, 50],
          [300, 100]
        ]
      )
      .build();
    var sponsoredLeaderboardMapping = googletag
      .sizeMapping()
      .addSize([800, 600], [[728, 90]])
      .addSize([0, 0], [[320, 50]])
      .build();
    var sponsoredBoomboxMapping = googletag
      .sizeMapping()
      .addSize([0, 0], [[300, 250]])
      .build();

    advertisements.forEach((node) => {
      node.id = node.id + "-" + shortid.generate();
      var isValid = false;
      var adTargetKey = node.getAttribute("data-adtargetkey") ? node.getAttribute("data-adtargetkey") : null;
      var adTargetValue = node.getAttribute("data-adtargetvalue") ? node.getAttribute("data-adtargetvalue") : null;

      if (/DROPDOWN/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(`/57835087/${node.getAttribute("data-zone")}`, [728, 90], node.id)
            .defineSizeMapping(topLeaderboardMapping)
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads())
        );
        isValid = true;
      } else if (/CLM_WEB_INLINEBANNER2/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(`/57835087/${node.getAttribute("data-zone")}`, [300, 250], node.id)
            .defineSizeMapping(sponsoredBoomboxMapping)
            .setCollapseEmptyDiv(true)
            .setTargeting("clmarticleno", node.getAttribute("data-articleid"))
            .addService(googletag.pubads())
        );
        isValid = true;
      } else if (/CLM-WEB_INLINE-BANNER/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(
              `/57835087/${node.getAttribute("data-zone")}`,
              [
                [728, 90],
                [320, 50]
              ],
              node.id
            )
            .defineSizeMapping(sponsoredLeaderboardMapping)
            .setCollapseEmptyDiv(true)
            .setTargeting("clmarticleno", node.getAttribute("data-articleid"))
            .addService(googletag.pubads())
        );
        isValid = true;
      } else if (/SMALL-LEADERBOARD/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(`/57835087/${node.getAttribute("data-zone")}`, [468, 60], node.id)
            .defineSizeMapping(smallLeaderboardMapping)
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads())
        );
        isValid = true;
      } else if (/LEADERBOARD_BOTTOM/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(`/57835087/${node.getAttribute("data-zone")}`, [468, 60], node.id)
            .defineSizeMapping(bottomLeaderboardMapping)
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads())
        );
        isValid = true;
      } else if (/LEADERBOARD_TOP/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(`/57835087/${node.getAttribute("data-zone")}`, [728, 90], node.id)
            .defineSizeMapping(topLeaderboardMapping)
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads())
        );
        isValid = true;
      } else if (/LEADERBOARD_POS/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(`/57835087/${node.getAttribute("data-zone")}`, [728, 90], node.id)
            .defineSizeMapping(topLeaderboardMapping)
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads())
        );
        isValid = true;
      } else if (/LEADERBOARD_NULL/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(`/57835087/${node.getAttribute("data-zone")}`, [728, 90], node.id)
            .defineSizeMapping(topLeaderboardMapping)
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads())
        );
        googletag.pubads().addEventListener("slotOnload", function (event) {
          var slot = event.slot;
          if (slot.getTargeting("refresh").indexOf("true") > -1 && topLeaderboardSlot == null) {
            topLeaderboardSlot = slot;
            setTimeout(_refreshTopLeaderboard, 10000);
          }
        });
        isValid = true;
      } else if (/RECTANGLE/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(`/57835087/${node.getAttribute("data-zone")}`, [300, 250], node.id)
            .defineSizeMapping(boomboxMapping)
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads())
        );
        isValid = true;
      } else if (/FOOTER/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(
              `/57835087/${node.getAttribute("data-zone")}`,
              [
                [1000, 60],
                [960, 45]
              ],
              node.id
            )
            .defineSizeMapping(torpedoMapping)
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads())
        );
        isValid = true;
      } else if (/ANCHOR/gi.test(node.getAttribute("data-zone")) || /TORPEDO/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(`/57835087/${node.getAttribute("data-zone")}`, [960, 45], node.id)
            .defineSizeMapping(torpedoMapping)
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads())
        );
        isValid = true;
      } else if (/HALF/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(
              `/57835087/${node.getAttribute("data-zone")}`,
              [
                [300, 600],
                [300, 250]
              ],
              node.id
            )
            .defineSizeMapping(halfPageMapping)
            .setCollapseEmptyDiv(true)
            .setTargeting(adTargetKey, adTargetValue)
            .addService(googletag.pubads())
        );
        isValid = true;
      } else if (/OUTOFPAGE/gi.test(node.getAttribute("data-zone"))) {
        if (window.innerWidth > 999) {
          gptAdSlots.push(
            googletag
              .defineOutOfPageSlot(`/57835087/${node.getAttribute("data-zone")}`, node.id)
              .addService(googletag.pubads())
          );
          isValid = true;
        }
      } else if (/BILLBOARD/gi.test(node.getAttribute("data-zone"))) {
        gptAdSlots.push(
          googletag
            .defineSlot(
              `/57835087/${node.getAttribute("data-zone")}`,
              [
                [970, 250],
                [320, 50]
              ],
              node.id
            )
            .defineSizeMapping(billBoardMapping)
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads())
        );
        isValid = true;
      }

      if (isValid) {
        googletag.display(node.id);
      }
    });

    googletag.pubads().disableInitialLoad();
    googletag.pubads().enableSingleRequest();

    googletag.pubads().addEventListener("slotRenderEnded", slotRenderEndedEvent);
    googletag.pubads().collapseEmptyDivs();
    googletag.enableServices();

    // Init or refresh
    if (!adSlots) {
      googletag.pubads().disableInitialLoad();
      googletag.pubads().enableSingleRequest();
      googletag.pubads().addEventListener("slotRenderEnded", slotRenderEndedEvent);
      googletag.pubads().collapseEmptyDivs();
      googletag.enableServices();
    }
    _triggerAdRefresh(gptAdSlots);
  });

  _handleResize();
  startTrackingImpressions();
};

// Observer options for attached articles
const articleObserverOptions = {
  rootMargin: "-25% 0%",
  threshold: 0
};
// Callback function for attached articles
const articleObserverCallback = (events) => {
  events.forEach((event) => {
    if (event.isIntersecting) {
      const fullSlug = event.target.querySelector("article").dataset.fullslug;
      const title = event.target.querySelector("article").dataset.title;

      // let gtmid = event[0].target.dataset.gtmid;
      const gtmdimensions = event.target.dataset.gtmdimensions;

      // Change URL when users scroll to the attached article
      if (!window.location.href.includes(fullSlug)) {
        if (!window.location.href.includes("preview?token=")) {
          const stateObj = {
            fullSlug: fullSlug,
            title: title
          };

          history.replaceState(stateObj, title, fullSlug);
        }

        // Change title when users scroll to the attached article
        document.title = title;

        // Update meta data when users scroll to the attached articles
        let metadata = event.target.querySelector("article").dataset.metadata;

        if (metadata) {
          metadata = JSON.parse(metadata);
          //loop through and update meta tag
          for (let i = 0; i < metadata.length; i++) {
            let metaElement = null;
            if (metadata[i].property) {
              metaElement = `meta[property="${metadata[i].property}"]`;
            } else if (metadata[i].name) {
              metaElement = `meta[name="${metadata[i].name}"]`;
            }
            let ele = document.querySelector(metaElement); // document.querySelector("meta[property='og:image']")
            if (ele) {
              document.querySelector(metaElement).setAttribute("content", metadata[i].content);
            }
          }
        }
        if (window.a2a) {
          const subject = `Check out this article from Today's Clinical Lab: ${title}`;
          const body = `I thought you might be interested in this article: \nhttps://www.clinicallab.com${fullSlug}`;
          var a2a_config = window.a2a_config || {};
          a2a_config.templates = a2a_config.templates || {};
          a2a_config.templates.email = {
            subject: subject,
            body: body
          };

          window.a2a.init_all();
        }

        // Push new gtm dimension to the datalayer when users scroll to the attached article
        if (gtmdimensions) {
          const gtmdimensionsObj = JSON.parse(gtmdimensions);

          window.dataLayer.push(gtmdimensionsObj);
        }

        // Set up lazy load background observer for attached articles
        setupLazyBackgroundObserver(event.target);
      }
    }
  });
};

const startSetupOfArticleObserver = () => {
  if ("IntersectionObserver" in window) {
    setupArticleObserver();
  } else {
    setTimeout(startSetupOfArticleObserver, 250);
  }
};

const setupArticleObserver = () => {
  var articleObserver = new IntersectionObserver(articleObserverCallback, articleObserverOptions);
  var articleObserverActive = false;
  var originalArticles = document.querySelectorAll("#ArticleView,#ConversionPageView");

  window.addEventListener("handleAttachedArticle", (data) => {
    if (!articleObserverActive) {
      originalArticles.forEach((article) => {
        articleObserver.observe(article);
      });
      articleObserverActive = true;
    }

    // init article with load styles
    articleInit($(data.detail));

    // add to change url observer
    articleObserver.observe(data.detail);
  });
};

const setupLazyBackgroundObserver = (element) => {
  var lazyBackgrounds = [].slice.call(element.querySelectorAll(".lazy-background"));

  if ("IntersectionObserver" in window) {
    let lazyBackgroundObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });

    lazyBackgrounds.forEach(function (lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  }
};

//I don't think we need this anymore, and causes some jank with lazy-loading
window.addEventListener("popstate", (event) => {
  if (event.state) {
    let article = document.querySelector(`#ArticleView article[data-fullSlug="${event.state.fullSlug}"]`).parentNode;
    let headerHeight = document.getElementById("MainHeader").scrollHeight;
    let articleOffset = article.getBoundingClientRect().top + window.scrollY - headerHeight - 25;

    window.scroll(0, articleOffset);
  } else {
    window.scroll(0, 0);
  }
});

// required for fixing smooth scroll
if ("scrollRestoration" in history) {
  history.scrollRestoration = "manual";
}

// tracking impressions for GTM Data Layer
const impressionsGTM = document.querySelectorAll(".gtm-impression");

const startTrackingImpressions = () => {
  if ("IntersectionObserver" in window) {
    trackingImpression();
  } else {
    setTimeout(startTrackingImpressions, 250);
  }
};

const trackingImpression = () => {
  if (impressionsGTM) {
    let impressionObserverCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0 && entry.target.dataset.gtmData) {
          let forDataLayer = JSON.parse(entry.target.dataset.gtmData);

          forDataLayer.event = "Impression";
          forDataLayer.timeStamp = new Date().toString();
          dataLayer.push(forDataLayer);
          observer.unobserve(entry.target);
        }
      });
    };

    let impressionObserverOptions = {
      threshold: 1
    };

    let impressionObserver = new IntersectionObserver(impressionObserverCallback, impressionObserverOptions);

    impressionsGTM.forEach((item) => {
      impressionObserver.observe(item);
    });
  }
};

// tracking clicks for GTM Data Layer
document.addEventListener("click", (event) => {
  let clickElement = event.target;
  let gtmClickElement = clickElement.closest(".gtm-click");

  if (gtmClickElement && gtmClickElement.dataset.gtmData) {
    let forDataLayer = JSON.parse(gtmClickElement.dataset.gtmData);

    if (!forDataLayer.event) forDataLayer.event = "Click";
    forDataLayer.timeStamp = new Date().toString();
    dataLayer.push(forDataLayer);
  }
});

let lastScroll = 0;
const adjustHeader = () => {
  let scroll = $(document).scrollTop();

  let header = $("#MainHeader");
  let preHeader = $("#preHeader");
  let headerOffset = header.offset().top;

  let stickySidebar = document.querySelectorAll("#Sidebar");
  let trendsNav = document.querySelector("#TrendsNav");
  let hasBillboard = preHeader.length;

  if (hasBillboard) {
    if (scroll > 0 && scroll > lastScroll && headerOffset - scroll <= 0 && scroll > preHeader.outerHeight(true)) {
      // when scroll down passed preHeader height, make the menu fixed
      header.addClass("fixed");
    } else if (scroll <= preHeader.outerHeight(true)) {
      // when the preHeader in the view
      header.removeClass("fixed");
    }
  }

  const scrollThreshold = preHeader.length ? preHeader.outerHeight() : 30;

  if (scroll > scrollThreshold && !header.hasClass("mobile-open")) {
    if (scroll < lastScroll) {
      header.removeClass("collapse");
      for (let i = 0; i < stickySidebar.length; i++) {
        stickySidebar[i].classList.add("headerShown");
      }
      if (trendsNav !== null) {
        trendsNav.classList.add("headerShown");
      }
    } else if (scroll > lastScroll) {
      header.addClass("collapse");
      for (let i = 0; i < stickySidebar.length; i++) {
        stickySidebar[i].classList.remove("headerShown");
      }
      if (trendsNav !== null) {
        trendsNav.classList.remove("headerShown");
      }
    }
  }

  lastScroll = scroll;
};

// PolyFills
if (!Element.prototype.remove) {
  Element.prototype.remove = function () {
    this.parentElement.removeChild(this);
  };
}
if (!NodeList.prototype.remove) {
  NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
    for (var i = this.length - 1; i >= 0; i--) {
      if (this[i] && this[i].parentElement) {
        this[i].parentElement.removeChild(this[i]);
      }
    }
  };
}

const slotRenderEndedEvent = () => {}; // close slotRenderEndedEvent

// Production steps of ECMA-262, Edition 5, 15.4.4.18
// Reference: http://es5.github.io/#x15.4.4.18
if (!Array.prototype.forEach) {
  Array.prototype.forEach = function (callback /*, thisArg*/) {
    var T, k;

    if (this == null) {
      throw new TypeError("this is null or not defined");
    }

    // 1. Let O be the result of calling toObject() passing the
    // |this| value as the argument.
    var O = Object(this);

    // 2. Let lenValue be the result of calling the Get() internal
    // method of O with the argument "length".
    // 3. Let len be toUint32(lenValue).
    var len = O.length >>> 0;

    // 4. If isCallable(callback) is false, throw a TypeError exception.
    // See: http://es5.github.com/#x9.11
    if (typeof callback !== "function") {
      throw new TypeError(callback + " is not a function");
    }

    // 5. If thisArg was supplied, let T be thisArg; else let
    // T be undefined.
    if (arguments.length > 1) {
      T = arguments[1];
    }

    // 6. Let k be 0.
    k = 0;

    // 7. Repeat while k < len.
    while (k < len) {
      var kValue;

      // a. Let Pk be ToString(k).
      //    This is implicit for LHS operands of the in operator.
      // b. Let kPresent be the result of calling the HasProperty
      //    internal method of O with argument Pk.
      //    This step can be combined with c.
      // c. If kPresent is true, then
      if (k in O) {
        // i. Let kValue be the result of calling the Get internal
        // method of O with argument Pk.
        kValue = O[k];

        // ii. Call the Call internal method of callback with T as
        // the this value and argument list containing kValue, k, and O.
        callback.call(T, kValue, k, O);
      }
      // d. Increase k by 1.
      k++;
    }
    // 8. return undefined.
  };
}
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

window.fetchQueryParams = () => {
  if (!window || !window.location || !window.location.search) return {};

  const urlParams = new URLSearchParams(window.location.search);
  const query = {};

  for (const [key, value] of urlParams) query[key] = value;
  return query;
};
