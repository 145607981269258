import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";

class Pagination extends React.Component {
    static propTypes = {
        data: PropTypes.object
    };

    static defaultProps = {
        data: {}
    };

    state = {
        isClient: false
    }

    navToPage = (page) => {
        const endpoint = this.props.data.endpoint ? this.props.data.endpoint : "";

        if(page === 1) {
            window.location = endpoint;
        } else {
            let qsElement = "?";

            if (/\?/g.test(endpoint)) {
                qsElement = "&";
            }

            window.location = `${endpoint}${qsElement}page=${page}`;
        }
    }

    componentDidMount() {
        this.setState({isClient: true});
    }

    render() {
        let page = parseInt(this.props.data.page);

        let totalItems = parseInt(this.props.data.totalItems);

        let totalPages = parseInt(this.props.data.totalPages);

        let pages = getPages(page, totalPages, this.state.isClient);

        let text = `Page ${page} of ${totalPages}  -  ${totalItems} Total Items`;

        let firstDisabled = page + "" === "1";

        let lastDisabled = page + "" === totalPages+"";

        if(totalPages > 0) {
            return (
                <div className="Pagination">
                    <div>
                        {text}
                    </div>
                    <div>
                        <button title="First" disabled={firstDisabled} onClick={()=>{
                            this.navToPage(1);
                        }}>
                            <i className="far fa-angle-double-left" aria-hidden="true"/>
                        </button>
                        <button title="Prev" disabled={firstDisabled} onClick={()=>{
                            if(page-1 >= 1){
                                this.navToPage(page-1);
                            }
                        }}>
                            <i className="far fa-angle-left" aria-hidden="true"/>
                        </button>
                        {pages.map((pageNum, index) => {
                            return(<button key={index} className={pageNum+"" === page+"" ? "selected": ""}  onClick={()=>{
                                this.navToPage(pageNum);
                            }}>{pageNum}</button>);
                        })}
                        <button title="Next" disabled={lastDisabled} onClick={()=>{
                            if(page+1 <= totalPages){
                                this.navToPage(page+1);
                            }
                        }}>
                            <i className="far fa-angle-right" aria-hidden="true"/>
                        </button>
                        <button title="Last" disabled={lastDisabled} onClick={()=>{
                            this.navToPage(totalPages);
                        }}>
                            <i className="far fa-angle-double-right" aria-hidden="true"/>
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <div />
            );
        }
    }
}

export default Pagination;

const getPages = (page, totalPages, isclient) => {
    let pages = [];

    let buffer = 4;

    if (isclient && $(window).width() <= 568) {
        buffer = 2;
    }

    let total = buffer*2+1;

    if(totalPages > total){
        if(page > buffer && page < totalPages - buffer) {
            for(let i = page-buffer; i<= page+buffer; i++){
                pages.push(i);
            }
        } else if (page <= buffer) {
            for(let i = 1; i<= total; i++){
                pages.push(i);
            }
        } else if (page >= totalPages - buffer) {
            for(let i = totalPages-total+1; i<= totalPages; i++){
                pages.push(i);
            }
        }
    } else {
        for(let i = 1; i<= totalPages; i++){
            pages.push(i);
        }
    }
    //pages = [22,23,24,25,26,27,28,29,30];
    return pages;
};
